import React from "react"

import Layout from "../../components/layout"

import SEO from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"

import OneTile from "../../components/products/oneTile"

const InnePage = () => {
  const data = useStaticQuery(graphql`
    query {
      allPlakatyYaml {
        edges {
          node {
            id
            name
            cena
            category
            fields {
              slug
            }
            pictures {
              childImageSharp {
                original {
                  src
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Inne prace" />

      <div className="products-main">
        {data.allPlakatyYaml.edges.map(({ node }) => {
          if (node.category === "inne") {
            return (
              <OneTile
                key={node.id}
                name={node.name}
                imgUrl={node.pictures[0].childImageSharp.original.src}
                imgWidth={node.pictures[0].childImageSharp.original.width}
                imgHeight={node.pictures[0].childImageSharp.original.height}
                url={node.fields.slug}
                cena={node.cena}
              />
            )
          }
          return null
        })}
      </div>
    </Layout>
  )
}

export default InnePage
