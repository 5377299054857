import React from "react"
import { Link } from "gatsby"

const oneTile = ({ name, imgUrl, url, imgWidth, imgHeight }) => {
  function posterClass() {
    if (imgHeight > imgWidth) {
      return "pionowy"
    } else if (imgHeight === imgWidth) {
      return "full"
    } else {
      return "poziomy"
    }
  }

  return (
    <Link className="oneTile" to={`/${url}`}>
      <div>
        <img className={posterClass()} alt={name} src={imgUrl} />
        <div className="plakatInfo">
          <h3>{name}</h3>
        </div>
      </div>
    </Link>
  )
}

export default oneTile
